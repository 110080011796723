import { ActivityNotificationService } from './theme/components/messages/activityNotifications.service';
import { EmailService } from './simlait/service/emai.service';
import { AgmCoreModule } from '@agm/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';
import { BlankComponent } from './pages/blank/blank.component';
import { AuthGuardService } from './pages/core/auth-guard.service';
import { AuthService } from './pages/core/auth.service';
import { TokenInterceptor } from './pages/core/token.interceptor';
import { ErrorComponent } from './pages/errors/error/error.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { PagesComponent } from './pages/pages.component';
import { SharedModule } from './shared/shared.module';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { TopInfoContentComponent } from './theme/components/top-info-content/top-info-content.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { PipesModule } from './theme/pipes/pipes.module';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { ToastrModule } from 'ngx-toastr';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import { JWTTokenService } from './pages/core/jwt.decoder';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { DetailComponent } from './theme/components/messages/detail/detail.component';
import { PrivacyService } from './simlait/service/privacy.service';
import { RoleService } from './simlait/roles/role.service';
import { FooterComponent } from './paens/footer/footer.component';

registerLocaleData(localeFr);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    }),
    PerfectScrollbarModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    SharedModule,
    PipesModule,
    AppRoutingModule,
    MatInputModule,

    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-top-full-width',
      preventDuplicates: true,
    }),

  ],
  declarations: [
    AppComponent,
    PagesComponent,
    BlankComponent,
    NotFoundComponent,
    ErrorComponent,
    TopInfoContentComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    FavoritesComponent,
    DetailComponent,
    FooterComponent,

  ],
  entryComponents: [
    VerticalMenuComponent
  ],
  providers: [
    AppSettings,
    RoleService,
    AuthGuardService,
    AuthService,
    JWTTokenService,
    EmailService,
    PrivacyService,
    ActivityNotificationService,
    { provide: LOCALE_ID, useValue: 'fr-FR' },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule { }
