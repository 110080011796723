import { environment } from '../../../environments/environment';

export class BackendServerConf {
  public static baseUrlBackend = "https://api.emargis.com";
  public static baseUrlBackendPrincipal = "https://api.emargis.com/api";
  public static webUrl = "https://emargis.com";

  
  //public static webUrl = "http://192.168.1.52:4200";

  //public static baseUrlBackend = "http://192.168.1.55:8000";
  //public static baseUrlBackendPrincipal = "http://192.168.1.55:8000/api";


  //public static baseUrlBackend = `${environment.apiUrl}`;
  // public static baseUrlBackend = "http://192.168.1.12:8000";
  //public static baseUrlBackendPrincipal = "https://apisimlait.mascosolutions.com/api";
  // public static serverUeadUploadDirFileUrl = "https://bdsp.massolutions.pro/upload-dir/";
  // public static serverUeadUploadDirFileUrl = "api/upload/nouveau/";
}
